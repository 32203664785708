import { BaseError } from '@equinor/echo-base';
import EchoCore from '@equinor/echo-core';

export async function request<T>(url: string, requestType: T): Promise<T> {
    const response: Response = await EchoCore.EchoClient.fetch(url);
    const contentType = response.headers.get('content-type');
    if (
        (response.status === 200 || response.status === 202) &&
        contentType &&
        contentType.indexOf('application/json') !== -1
    ) {
        try {
            requestType = JSON.parse(await response.text());
        } catch (exception) {
            throw new BaseError({
                name: '[client.api-manager.request]',
                message: 'Could not parse JSON',
                innerError: { parseError: exception, url }
            });
        }
    } else if (
        (response.status === 200 || response.status === 202) &&
        contentType &&
        contentType.indexOf('text/plain') !== -1
    ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const text: any = await response.text();
        requestType = text;
    } else if (
        ((response.status === 200 || response.status === 202) && contentType && contentType.indexOf('image/') >= 0) ||
        (contentType && contentType.indexOf('video/') >= 0)
    ) {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            requestType = (await response.arrayBuffer()) as any;
        } catch (exception) {
            if (typeof exception === 'string') {
                throw new Error(exception);
            } else {
                throw new Error(
                    `Unknown error while processing request. The exception in string format: ${JSON.stringify(
                        exception
                    )}`
                );
            }
        }
    }

    return requestType;
}
