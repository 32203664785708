// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.echo-Home__wrapper-OTYWP {
    padding-top: 150px;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.echo-Home__heading-vnGkQ {
    padding-left: 18px;
}

.echo-Home__brandSliderContainer-qt0N4 {
    display: flex;
    flex: 1 1;
    flex-flow: column-reverse;
    max-height: 350px;
    margin: 0;
    width: 100%;
    position: fixed;
    bottom: 55px;
    overflow: hidden;
    object-fit: cover;
}

.echo-Home__appBar-XBn9u {
    margin: 0 auto 48px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/components/Home/home.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,yBAAyB;IACzB,iBAAiB;IACjB,SAAS;IACT,WAAW;IACX,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,uBAAuB;AAC3B","sourcesContent":[".wrapper {\r\n    padding-top: 150px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: baseline;\r\n}\r\n\r\n.heading {\r\n    padding-left: 18px;\r\n}\r\n\r\n.brandSliderContainer {\r\n    display: flex;\r\n    flex: 1 1;\r\n    flex-flow: column-reverse;\r\n    max-height: 350px;\r\n    margin: 0;\r\n    width: 100%;\r\n    position: fixed;\r\n    bottom: 55px;\r\n    overflow: hidden;\r\n    object-fit: cover;\r\n}\r\n\r\n.appBar {\r\n    margin: 0 auto 48px;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n    align-items: flex-start;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `echo-Home__wrapper-OTYWP`,
	"heading": `echo-Home__heading-vnGkQ`,
	"brandSliderContainer": `echo-Home__brandSliderContainer-qt0N4`,
	"appBar": `echo-Home__appBar-XBn9u`
};
export default ___CSS_LOADER_EXPORT___;
